import styled from 'styled-components'

export const ContactContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  background: #f8f8f8;

  .texto {
    margin-top: 20px;

    &.email-texto {
      display: block;
    }
  }

  .image-contact {
    width: 100%;
    max-width: 455px;
  }

  .phones-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;

    .ctn-label-phones {
      display: flex;
      flex-direction: column;

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
      }

      .phones {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        p {
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          margin-left: 10px;
          color: #ed0f69;
        }
      }

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }

  @media (max-width: 992px) {
    .titulo-section {
      margin-top: 30px;
    }

    .phones-container {
      flex-direction: column;
      margin-bottom: 40px;

      .ctn-label-phones {
        .phones {
        }

        &:nth-child(2) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
`
