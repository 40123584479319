import styled from 'styled-components'

export const CardsStyled = styled.div`
  padding: 30px;

  background: white;
  -webkit-box-shadow: -4px 5px 5px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -4px 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: -4px 5px 5px -3px rgba(0, 0, 0, 0.2);

  .content {
    margin-top: 20px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    font-family: 'ASAP', Verdana;
    color: #77797b;

    p,
    br {
      margin: 0;
      line-height: 20px !important;
    }
  }

  .ctn-titulo {
    display: flex;
    flex-direction: row;
    align-items: center;

    .foto,
    .foto-spacer {
      width: 48px;
      height: 48px;
      min-width: 48px;
      background: #cbcbcb;
      border-radius: 5px;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      margin-left: 10px;
    }
  }

  .link-perfil {
    display: block;
    margin-top: 20px;
    font-family: 'ASAP', Verdana;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.linkColor};
  }
`
