import styled from 'styled-components'

export const AccordionContainer = styled.div`
  background: white;
  border-left: 8px solid #ffffff;

  padding: 30px;
  padding-right: 38px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #464646;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #464646;
    margin-bottom: 20px;
  }

  .content {
    color: #464646;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  hr {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .btn-showall {
    color: #ed0f69;
    border: none;
    box-shadow: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 992px) {
  }
`
