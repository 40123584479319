import styled from 'styled-components'

export const ClinicalStaffContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  background: #ebebeb;

  .button-see-all {
    margin-top: 40px;
  }

  @media (max-width: 992px) {
    .button-see-all {
      margin-bottom: 30px;
    }

    .card-doctors {
      margin-bottom: 30px;
    }
  }
`
