import React from 'react'

import { Row, Col } from 'atomic'

import { ExamsContainer } from './styled'

import Title from 'site/src/components/landing-design-system/title'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const chunkArray = (arr, len) => {
  var chunks = [],
    i = 0,
    n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

export default props => {
  const carrouselArray = chunkArray(props.lista_exames.items, 7)

  return (
    <ExamsContainer>
      <Row>
        <Col xs={12} lg={4}>
          <h2>
            <span>{props.hat}</span>
          </h2>
          <Title lineColor="#ED0F69">{props.titulo}</Title>
        </Col>

        <Col xs={12} lg={7} lgOffset={1}>
          <Row className="exams-list">
            {props.lista_exames.items.map((exame, index) => (
              <Col xs={12} lg={3} key={`link-exam-${index}`}>
                <a href={exame.link} target="_blank" rel="noopener noreferrer">
                  {exame.nome}
                </a>
              </Col>
            ))}
          </Row>
        </Col>

        <Carousel>
          {carrouselArray.map((arrayItems, index) => (
            <div className="item-carrousel" key={`carrousel-item-${index}`}>
              {arrayItems.map((exame, indexlink) => (
                <a
                  key={`link-carrousel-item-${indexlink}`}
                  className="linkCarrousel"
                  href={exame.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {exame.nome}
                </a>
              ))}
            </div>
          ))}
        </Carousel>
      </Row>
    </ExamsContainer>
  )
}
