import React from 'react'

import { Grid, Row, Col } from 'atomic'

import { SpecialitiesContainer } from './styled'

import Title from 'site/src/components/landing-design-system/title'

import Accordion from './accordion'

export default props => {
  return (
    <SpecialitiesContainer>
      <Grid>
        <Row>
          <Col xs={12} lg={12}>
            <h2>
              <span>{props.hat}</span>
            </h2>
            <Title lineColor="#ED0F69">{props.titulo}</Title>
          </Col>

          <Col xs={12} lg={10} lgOffset={1} className="accordion-container">
            {props.items.map((item, index) => (
              <Accordion
                key={`accordion-specilties-${index}}`}
                titulo={item.titulo}
                subtitulo={item.subtitulo}
                items_max_chars={props.items_max_chars}
              >
                {item.descricao}
              </Accordion>
            ))}
          </Col>
        </Row>
      </Grid>
    </SpecialitiesContainer>
  )
}
