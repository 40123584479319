import React from 'react'

import { Grid, Row, Col } from 'atomic'

import { ContactContainer } from './styled'

import Title from 'site/src/components/landing-design-system/title'

import svgWhats from './icons/whatsapp.svg'
import svgPhone from './icons/phone.svg'

export default props => {
  const cleanPhone =
    props.whatsapp
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(/\s/g, '') || ''
  return (
    <ContactContainer>
      <Grid>
        <Row>
          <Col xs={12} lg={5}>
            <img className="image-contact" src={props.imagem.url} alt={props.titulo} />
          </Col>
          <Col xs={12} lg={6} lgOffset={1}>
            <h2 className="titulo-section">
              <span>{props.hat}</span>
            </h2>
            <Title lineColor="#ED0F69">{props.titulo}</Title>
            <div className="texto" dangerouslySetInnerHTML={{ __html: props.texto }} />

            <div className="phones-container">
              <div className="ctn-label-phones">
                {props.whatsapp_label && <p className="label-phone">{props.whatsapp_label}</p>}

                {props.whatsapp && (
                  <a
                    href={`https://wa.me/55${cleanPhone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="phones"
                  >
                    <img src={svgWhats} alt="whatsapp" />
                    <p>{props.whatsapp}</p>
                  </a>
                )}
              </div>
              <div className="ctn-label-phones">
                {props.telefone_label && <p className="label-phone">{props.telefone_label}</p>}
                {props.telefone && (
                  <a href={`tel:${props.telefone}`} className="phones">
                    <img src={svgPhone} alt="phone" />
                    <p>{props.telefone}</p>
                  </a>
                )}
              </div>
            </div>

            {props.email_texto && (
              <div
                className="texto email--texto"
                dangerouslySetInnerHTML={{ __html: props.email_texto }}
              />
            )}
          </Col>
        </Row>
      </Grid>
    </ContactContainer>
  )
}
