import React from 'react'

import { CardsStyled } from './styled'

const Card = props => (
  <CardsStyled className="card-doctors" {...props}>
    <div className="ctn-titulo">
      {props.foto ? (
        <img src={`https://imgix.cosmicjs.com/${props.foto}`} alt="foto-medico" className="foto" />
      ) : (
        <div className="foto-spacer" />
      )}

      <p className="title">{props.titulo}</p>
    </div>

    <div
      className="content"
      dangerouslySetInnerHTML={{
        __html: props.descricao
      }}
    />

    <a
      className="link-perfil"
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.fleury.com.br/corpo-clinico/${props.slug}`}
    >
      Ver Perfil
    </a>
  </CardsStyled>
)

Card.defaultProps = {
  linkColor: '#7e030c'
}

export default Card
