import styled from 'styled-components'

export const PresentationContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  .texto {
    margin-top: 20px;
  }

  .image-presentation {
    width: 100%;
    max-width: 465px;
  }

  @media (max-width: 992px) {
    .image-presentation {
      margin-top: 20px;
    }
  }
`
