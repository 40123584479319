import styled from 'styled-components'

export const ExamsContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
  display: flex;

  .exams-list {
    margin-top: 60px;
    align-items: center;
    a {
      display: flex;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      font-family: 'ASAP', Verdana;
      margin-bottom: 10px;
      margin-right: 20px;
      word-break: break-word;
    }
  }

  .carousel-root {
    margin-top: 40px;
    display: none;
    .item-carrousel {
      background: white;
      padding-bottom: 40px;
      padding-left: 0.5em;
      padding-right: 0.5em;
      a {
        display: flex;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        font-family: 'ASAP', Verdana;
        margin-bottom: 10px;
        margin-right: 20px;
        word-break: break-word;
        margin-bottom: 20px;
      }
    }

    .control-arrow,
    .carousel-status {
      display: none;
    }

    .slide.selected {
      background: white;
    }

    .dot {
      background: #b4b5b4 !important;
      opacity: 1 !important;
      margin: 0 3px !important;
      &.selected {
        background: #ed0f69 !important;
      }
    }
  }

  @media (max-width: 992px) {
    padding-top: 50px;
    .exams-list {
      display: none;
    }

    .carousel-root {
      display: block;
    }
  }
`
