import React, { useState } from 'react'

import { AccordionContainer } from './styled'

export default props => {
  const shortDesc = (props.children || '').substring(0, parseInt(props.items_max_chars))
  const [showAll, setShowAll] = useState(false)
  return (
    <AccordionContainer className="accordion">
      <p className="title">{props.titulo}</p>
      <p className="subtitle">{props.subtitulo}</p>

      {showAll ? (
        <div className="content" dangerouslySetInnerHTML={{ __html: props.children }} />
      ) : (
        <div className="content" dangerouslySetInnerHTML={{ __html: `${shortDesc}...` }} />
      )}

      <hr />
      <button
        className="btn-showall"
        onClick={() => {
          setShowAll(!showAll)
        }}
      >
        {showAll ? 'Mostrar menos' : 'Mostrar mais'}
      </button>
    </AccordionContainer>
  )
}
