import styled from 'styled-components'

export const SpecialitiesContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
  background: #f8f8f8;

  .accordion-container {
    margin-top: 40px;
    .accordion {
      &:nth-child(odd) {
        border-left: 8px solid #b5b5b5;
      }
      &:nth-child(even) {
        border-left: 8px solid #e4e4e5;
      }
    }
  }

  @media (max-width: 992px) {
  }
`
