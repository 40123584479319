import React from 'react'

import { Row, Col, Grid } from 'atomic'

import { ClinicalStaffContainer } from './styled'

import Hat from './../hat'
import Title from './../title'

import Button from './../button'

import Card from './cards'

const ClinicalStaff = props => {
  return (
    <ClinicalStaffContainer>
      <Grid>
        <Row>
          <Col xs={12} lg={3} className="ctn-esquerda">
            <Hat color={props.themeColor}>{props.hat}</Hat>
            <Title lineColor={props.themeColor}>{props.titulo}</Title>
            <Button
              href={props.link_ver_todos}
              target="_blank"
              className="button-see-all"
              themeColor={props.themeColor}
            >
              Ver Todos
            </Button>
          </Col>

          {props.medicos.slice(0, 3).map((medico, index) => {
            const { metafields } = medico
            const foto = metafields.find(item => item.key === 'foto').value

            const descricao =
              metafields.find(item => item.key === 'descricaoCompleta').value ||
              (metafields.find(item => item.key === 'seo').children || []).find(
                item => item.key === 'descricao'
              ).value

            return (
              <Col xs={12} lg={3} className="ctn-direita" key={`clinical-staff-col${index}`}>
                <Card
                  foto={foto}
                  titulo={medico.title}
                  descricao={descricao}
                  slug={medico.slug}
                  linkColor={props.themeColor}
                />
              </Col>
            )
          })}
        </Row>
      </Grid>
    </ClinicalStaffContainer>
  )
}

ClinicalStaff.defaultProps = {
  themeColor: '#7e030c'
}

export default ClinicalStaff
