import styled from 'styled-components'

export const MedicinaContainer = styled.div`
  h1,
  h2,
  h3,
  h4.h5,
  p,
  span,
  a {
    color: ##77797b;
  }

  width: 100%;
  overflow-x: hidden;
`
