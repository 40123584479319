import React from 'react'

import IndexLayout from 'site/src/components/org.layout/layout.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { Grid } from 'atomic'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { graphql } from 'gatsby'
import { MedicinaContainer } from './styled'
import Presentation from './presentation'
import Covid from './covid'
import Specialities from './specialities'
import Exams from './exams'
import Contact from './contact'
import ClinicalStaff from 'site/src/components/landing-design-system/clinical-staff'

export default props => {
  const { metadata } = props.data.cosmicjsLandingPages

  return (
    <IndexLayout location={props.location}>
      <SEO
        socialMedia={{
          canonicalUrl: `${props.location.href}`,
          title: metadata.apresentacao.titulo,
          image: metadata.apresentacao.imagem.url,
          imageAlt: metadata.apresentacao.titulo,
          description: metadata.apresentacao.texto
        }}
      />
      <Grid>
        <TitleWithBreadcrumbRow addtionalDictionary={{ '/medicina-fetal': 'Medicina Fetal' }} />
      </Grid>
      <MedicinaContainer>
        <Grid>
          <Presentation {...metadata.apresentacao} />
          <Covid {...metadata.protocolo_covid} />
        </Grid>

        <Specialities {...metadata.especialidades} />

        <Grid>
          <Exams {...metadata.exames} />
        </Grid>

        <Contact {...metadata.contato} />

        <ClinicalStaff {...metadata.corpo_clinico} themeColor="#ED0F69" />
      </MedicinaContainer>
    </IndexLayout>
  )
}

export const query = graphql`
  query MedicinaFetal {
    cosmicjsLandingPages(slug: { eq: "land-medicina-fetal" }) {
      id
      metadata {
        apresentacao {
          hat
          titulo
          texto
          imagem {
            url
          }
        }
        protocolo_covid {
          hat
          titulo
          texto
          topicos {
            texto
          }
        }
        especialidades {
          hat
          titulo
          items_max_chars
          items {
            titulo
            subtitulo
            descricao
          }
        }
        exames {
          hat
          titulo
          lista_exames {
            items {
              nome
              link
            }
          }
        }
        contato {
          hat
          titulo
          texto
          whatsapp_label
          whatsapp
          telefone_label
          telefone
          email_texto
          imagem {
            url
          }
        }
        corpo_clinico {
          hat
          titulo
          link_ver_todos
          medicos {
            title
            slug
            metafields {
              title
              key
              value
              children {
                key
                title
                value
              }
            }
          }
        }
      }
    }
  }
`
