import React from 'react'

import { HatStyled } from './styled'

const Hat = props => <HatStyled {...props}>{props.children}</HatStyled>

Hat.defaultProps = {
  color: '#7e030c'
}

export default Hat
