import React from 'react'

import { ButtonStyled } from './styled'

const Button = props => <ButtonStyled {...props}>{props.children}</ButtonStyled>

Button.defaultProps = {
  themeColor: '#7f040e'
}

export default Button
