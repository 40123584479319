import React from 'react'

import { Row, Col } from 'atomic'

import { PresentationContainer } from './styled'

import Title from 'site/src/components/landing-design-system/title'

export default props => {
  return (
    <PresentationContainer>
      <Row>
        <Col xs={12} lg={5}>
          <h2>
            <span>{props.hat}</span>
          </h2>
          <Title lineColor="#ED0F69">{props.titulo}</Title>
          <div className="texto" dangerouslySetInnerHTML={{ __html: props.texto }} />
        </Col>
        <Col xs={12} lg={5} lgOffset={2}>
          <img className="image-presentation" src={props.imagem.url} alt={props.titulo} />
        </Col>
      </Row>
    </PresentationContainer>
  )
}
