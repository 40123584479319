import React from 'react'

import { Row, Col } from 'atomic'

import { CovidContainer } from './styled'

import Title from 'site/src/components/landing-design-system/title'

const Stroke = props => {
  return (
    <CovidContainer>
      <Row>
        <Col xs={12} lg={4}>
          <h2>
            <span>{props.hat}</span>
          </h2>
          <Title lineColor="#ED0F69">{props.titulo}</Title>
          <div className="texto" dangerouslySetInnerHTML={{ __html: props.texto }} />
        </Col>
        <Col className="topicos" xs={12} lg={7} lgOffset={1}>
          {props.topicos.map((topico, index) => (
            <Col className="topicos-item" xs={12} lg={6} key={`topicos-item-${index}`}>
              <div className="number">{index + 1}</div>
              <div dangerouslySetInnerHTML={{ __html: topico.texto }}></div>
            </Col>
          ))}
        </Col>
      </Row>
    </CovidContainer>
  )
}

Stroke.defaultProps = {
  lineColor: '#7f040e'
}

export default Stroke
