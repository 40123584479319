import styled from 'styled-components'

export const ButtonStyled = styled.a`
  border: 1px solid ${props => props.themeColor};
  height: 45px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.themeColor};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'ASAP', Verdana;
  max-width: 170px;
`
