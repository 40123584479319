import styled from 'styled-components'

export const CovidContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
  display: flex;

  .texto {
    margin-top: 20px;
  }

  .topicos {
    min-height: 630px;
    height: auto;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    margin-top: 130px;
    align-items: flext-start;

    .topicos-item {
      position: relative;
      padding-right: 50px !important;

      .number {
        /* font-family: 'ASAP'; */
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        color: #ed0f69;
        opacity: 0.1;
        position: absolute;
        top: -17px;
        left: -7px;
      }
    }
  }

  @media (max-width: 992px) {
    .topicos {
      margin-top: 40px;
      .topicos-item {
        padding-right: 0.5rem !important;
      }
    }
  }
`
